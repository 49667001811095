import {TrackerEventEmitter} from "./TrackerEventEmitter";

export default class TrackerManager {
  constructor(trackers) {
    trackers = trackers || {};
    this.trackers = {};
    this.eventEmitter = new TrackerEventEmitter();

    for(let i in trackers){
      this.push(i, trackers[i]);
    }
  }

  push(key, tracker) {
    this.trackers[key] = new tracker(this.eventEmitter);
  }

  subscribe(...args) {
    this.eventEmitter.subscribe(...args);
  }

  emit (...args) {
    return this.eventEmitter.emit(...args);
  }
}
