import {tns} from "tiny-slider/dist/tiny-slider";
import {SliderComponent} from "../../../../includes/Components/Slider/SliderDefault/SliderComponent";

(function ($, ns) {
  "use strict";

  var moduleActuTheme = function ($context, opts) {
    this.dom = $context;
    this.$context = $($context);

    var defaultOpts = {
      pushState: (ns.actu && ns.actu.opts && ns.actu.opts.pushState !== undefined) ? ns.actu.opts.pushState : true,
      autoSubmitFilters: (ns.actu && ns.actu.opts && ns.actu.opts.autoSubmitFilters !== undefined) ? ns.actu.opts.autoSubmitFilters : true,
    };
    opts = opts || {};
    this.opts = $.extend(defaultOpts, opts);

    this.init();
  };

  var parentPrototype = window.pew.getRegistryEntry("wdf-plugin-actu").classDef.prototype;
  moduleActuTheme.prototype = jQuery.extend({}, parentPrototype);

  moduleActuTheme.prototype.registerEvents = function () {
    parentPrototype.registerEvents.call(this);
    if(this.$context.find('.actus-carousel').length > 0){
      this.registerSlider();
    }
  };

  // see https://bxslider.com/options/
  moduleActuTheme.prototype.registerSlider = function () {
    var $actuList = this.$context.find(".actu-list");
    $actuList.removeClass("items-list");
    const slideWidth = 350; //$actuList.find('> li:first-child').innerWidth();

    this.slider = new SliderComponent($actuList, {
      fixedWidth: slideWidth,
      gutter: 10,
      loop: false,
      items: 1,
      slideBy: 1,
      navPosition: "bottom",
      autoHeight: false,
      pager: true,
      controls: true,
      touchEnabled: true,
      responsive: {
        768: {
          items: 2,
          slideBy: 1,
          pager: false,
        },
        960: {
          items: 3,
          slideBy: 1
        }
      }
    });


  };

  window.pew.addRegistryEntry({key: "wdf-plugin-actu", domSelector: ".module-actu", classDef: moduleActuTheme});

})(jQuery, window.wonderwp);
