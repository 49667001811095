(function ($, ns) {
  "use strict";

  var moduleActu = function ($context, opts) {
    this.dom = $context;
    this.$context = $($context);

    var defaultOpts = {
      pushState: (ns.actu && ns.actu.opts && ns.actu.opts.pushState !== undefined) ? ns.actu.opts.pushState : true,
      autoSubmitFilters: (ns.actu && ns.actu.opts && ns.actu.opts.autoSubmitFilters !== undefined) ? ns.actu.opts.autoSubmitFilters : true,
    };
    opts = opts || {};
    this.opts = $.extend(defaultOpts, opts);

    this.init();
  };

  moduleActu.prototype = {
    init: function () {
      var self = this;
      self.registerEvents();

      if (this.opts.pushState) {
        window.history && history.replaceState({
          'module': 'actu',
          'href': window.location.href
        }, null, window.location.href);
        window.onpopstate = function (event) {
          if (event.state && event.state.module && event.state.module === 'actu' && event.state.href) {
            self.loadPage(event.state.href);
          }
        }
      }

      self.recomputeIndexes();
    },
    registerEvents: function () {
      this.registerPagination();
      this.registerSearch(this.opts.autoSubmitFilters);
      this.registerReset();
    },
    recomputeIndexes: function () {
      jQuery('.module-actu').each(function (i, instance) {
        $(instance).data('index', i);
      });
    },
    loadPage: function (href, cb) {
      var self = this;
      self.$context.addClass('ajax-loading');
      $.get(href, {}, function (res) {
        self.$context.removeClass('ajax-loading');
        var index = self.$context.data('index');
        var $newContext = null;
        if (index) {
          $newContext = $($(res).find('.module-actu')[index]);
          $newContext.data('index', index);
        } else {
          $newContext = $(res).find('.module-actu').first();
        }
        self.$context.replaceWith($newContext);
        self.$context = $newContext;

        var topPos = self.$context && self.$context.offset() && self.$context.offset().top ? self.$context.offset().top : null;
        if (topPos) {
          if (window.smoothScrollMargin) {
            topPos -= window.smoothScrollMargin;
          }

          $('html,body').animate({
            scrollTop: topPos
          }, 750);
        }

        self.registerEvents();

        if (self.opts.pushState) {
          window.history && history.pushState({'module': 'actu', 'href': href}, null, href);
        }

        if (ns.theme && ns.theme.runCurrentPageJs) {
          ns.theme.runCurrentPageJs(self.$context);
        }
        if (window.pew) {
          window.pew.enhanceRegistry(self.$context[0]);
        }
        cb && cb();
      });
    },
    registerPagination: function () {
      var self = this;
      this.$context.find('.pagination a.navlink').on('click', function (e) {
        e.preventDefault();
        $(this).replaceWith('<span class="loader loader--small"></span>')
        self.loadPage($(this).attr('href'));
      })
    },
    registerSearch: function (autoSubmit) {
      var self = this,
        $filtersForm = this.$context.find('form.news-filters');

      $filtersForm.on('submit', function (e) {
        var $form = $(this);
        e.preventDefault();
        if (!self.$context.hasClass('ajax-loading')) {
          self.loadPage($form.prop('action') + '?' + $form.serialize());
        }
      });
      if (autoSubmit) {
        $filtersForm.addClass('auto-submit');

        //auto submit on input change
        var $inputs = $filtersForm.find('.checkbox, input, select').not('.exclude');
        $inputs.on('change', function () {
          $(this).parent().addClass('currently-loading');
          $filtersForm.submit();
        });

        //Possibility to uncheck a radio input
        $("input:radio:checked").data("chk", true);
        $inputs.on('click', function () {
          //on valid if it's a radio input
          if (!$(this).is(':radio')) {
            return;
          }
          $(this).data("chk", !$(this).data("chk"));
          $(this).prop("checked", $(this).data("chk")).trigger('change');
        });
      }
    },
    registerReset: function () {
      var $filtersForm = this.$context.find('.news-filters');
      $filtersForm.find('button[type="reset"]').on('click', function () {
        $filtersForm.find('select, input[type="text"], textarea').val('');
        $filtersForm.find('input[type="checkbox"], input[type="radio"]').prop('checked', false).attr('checked', false);
        $filtersForm.submit();
      });
    }
  };

  if (ns && ns.app) {
    ns.app.registerModule('actu', moduleActu);
  } else {
    window.pew.addRegistryEntry({key: 'wdf-plugin-actu', domSelector: '.module-actu', classDef: moduleActu});
  }

})(jQuery, window.wonderwp);
