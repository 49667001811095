/**
 * rgpd.js. Created by jeremydesvaux the 16 mai 2014
 */
(function ($, ns) {

  "use strict";

  /**
   * init module scripts, relative to its context (multiple context of the same module may exist in a page)
   * @param context
   */
  var rgpd = function (context) {
    this.$context = (context instanceof jQuery) ? context : $(context);
    this.init();
  };

  rgpd.prototype = {
    init: function () {
      if (this.$context.hasClass('consents-form-wrap')) {
        this.registerConsentForm();
      } else if (this.$context.hasClass('auth-form-wrap')) {
        this.registerAuthForm();
      }
    },
    registerConsentForm: function () {
      var cf = new ConsentForm(this.$context.find('> form'));
      cf.init();
    },
    registerAuthForm: function () {
      var af = new AuthForm(this.$context.find('.wwpform'));
      af.init();
    }

  };

  var ConsentForm = function ($form) {
    this.$form = $form;
  };

  ConsentForm.prototype = {
    init: function () {
      this.registerSubmit();
    },
    registerSubmit: function () {
      var self = this;
      this.$form.find('.submit-trigger').on('click', function (e) {
        e.preventDefault();
        self.showConfirmation(false);
        self.scrollToTop();
      });
      this.$form.find('.submit-wrap .cancel').on('click', function (e) {
        e.preventDefault();
        self.showConfirmation(true);
        self.scrollToTop();
      });
      this.$form.on('submit', function (e) {
        setTimeout(function () {
          self.$form.removeClass('loading');
        }, 50);
      });
    },
    scrollToTop: function () {
      $('html,body').animate({
        scrollTop: 0
      }, 500);
    },
    showConfirmation: function (show) {
      var $consentsWrap = this.$form.find('.consents-wrap'),
        $confirmWrap = this.$form.find('.confirm-wrap'),
        $submitTrigger = this.$form.find('.submit-trigger'),
        $exportTrigger = this.$form.find('.export-btn')
      ;
      this.toggleConsents(show, $consentsWrap, $confirmWrap, $submitTrigger, $exportTrigger);
    },
    toggleConsents: function (showUncheckedConsents, $consentsWrap, $confirmWrap, $submitTrigger, $exportTrigger) {
      var self = this;
      if ($consentsWrap.length > 0) {
        $consentsWrap.each(function (i, cw) {
          var $cw = $(cw),
            $consents = $cw.find('.consent-item'),
            consentsLength = $consents.length,
            consentsChecked = 0;

          if (consentsLength > 0) {
            $consents.each(function (i, c) {
              var $thisConsent = $(c);
              var $cb = $thisConsent.find('.checkbox');
              if (!$cb.is(':checked')) {
                if (showUncheckedConsents) {
                  $thisConsent.slideDown('slow');
                } else {
                  $thisConsent.slideUp('slow');
                }
              } else {
                consentsChecked++;
                if (!showUncheckedConsents) {
                  $thisConsent.find('.detail-consent').slideUp('slow');
                } else {
                  $thisConsent.find('.detail-consent').slideDown('slow');
                }
              }
            });
          }
          if (showUncheckedConsents) {
            $cw.fadeIn();
            $cw.find('.subtitle').show();
            $cw.find('.beforeDeleteWarning').hide();
          } else {
            if (consentsChecked === 0) {
              $cw.slideUp('slow');
            } else {
              $cw.slideDown('slow');
              $cw.find('.subtitle').hide();
              $cw.find('.beforeDeleteWarning').show();
            }
          }
        });
      }

      if (showUncheckedConsents) {
        $submitTrigger.slideDown();
        $confirmWrap.slideUp();
        $exportTrigger.fadeIn();
      } else {
        $submitTrigger.slideUp();
        $confirmWrap.slideDown();
        $exportTrigger.fadeOut();
      }

    }
  };

  var AuthForm = function ($form) {
    this.$form = $form;
  };

  AuthForm.prototype = {
    init: function () {
      this.registerSubmit();
    },
    registerSubmit: function () {
      var self = this;
      this.$form.on('submit', function (e) {
        e.preventDefault();
        self.handleSubmit(self.$form);
      });
    },
    handleSubmit: function ($form) {
      var t = this;

      //check form validity
      if ($form.valid && !$form.valid()) {
        return false;
      }
      if ($form.hasClass('loading')) {
        return false;
      }

      t.clearNotifications($form);

      var formData = new FormData($form[0]);
      $form.addClass('loading');
      $form.find('[type="submit"]').prop("disabled", true);

      $.ajax($.extend({
        url: $form.attr('action'),
        data: formData,
      }, t.getAjaxParams()))
        .done(function (data, textStatus, jqXHR) {
          t.submitCallBack(data, $form);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          t.submitCallBack({code: 500}, $form);
        })
        .always(function () {
          $form.removeClass('loading');
        });
    },
    getAjaxParams: function () {
      return {
        type: 'POST',
        cache: false,
        contentType: false,
        processData: false
      };
    },
    submitCallBack: function (res, $form) {
      var t = this;
      if (res && res.code && res.code === 200) {
        t.onSubmitSuccess(res, $form);
        setTimeout(function () {
          $form.find('[type="submit"]').prop('disabled', false);
        }, 5000);
      } else {
        t.onSubmitError(res, $form);
        $form.find('[type="submit"]').prop('disabled', false);
      }
    },
    onSubmitSuccess: function (res, $form) {
      this.notify('success', res.message.translated, $form);
      $form[0].reset();
    },
    onSubmitError: function (res, $form) {
      var notifType = res && res.code && res.code === 202 ? 'info' : 'error',
        notifMsg = res && res.message && res.message.translated ? res.message.translated : 'Error';
      this.notify(notifType, notifMsg, $form);
    },
    notify: function (type, msg, $context) {
      var EventManager = window.EventManager || $(document);
      EventManager.trigger('notification', {
        type: type,
        msg: msg,
        dest: $context,
        focus: true
      });
    },
    clearNotifications: function($dest) {
      var $alerts = $dest.find('.alert');
      if ($alerts.length) {
        $alerts.fadeOut(400, function () {
          $(this).remove();
        });
      }
    }
  };

  if (ns && ns.app) {
    ns.app.registerModule('rgpd', rgpd);
  } else {
    window.pew.addRegistryEntry({key: 'wdf-plugin-rgpd', domSelector: '.module-rgpd', classDef: rgpd});
  }

})(jQuery, window.wonderwp);
