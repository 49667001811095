export class MatomoProcessor {
  constructor(trackerEvent) {
    //trackerEvent.subscribe('Tracking.pageEvent', (data) => this.registerPageEvent(data));
    trackerEvent.subscribe('Tracking.customEvent', (data) => this.registerCustomEvent(data));
  }

  registerCustomEvent({category, action, label, value, cb}) {
    if (window._paq && window._paq.push) {
      _paq.push(['trackEvent', category, action, label, value]);
    } else {
      cb && cb('notag');
    }
  }
}
