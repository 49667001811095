(function ($, ns) {

    "use strict";

    /**
     * init module scripts, relative to its context (multiple context of the same module may exist in a page)
     * @param context wraper div of the module
     */
    var WwpVideo = function (context) {
        this.$context = (context instanceof jQuery) ? context : $(context);
        this.init();
    };

    WwpVideo.prototype = {
        init: function () {
            this.checkIfVideoShouldBeOpened();
        },
        checkIfVideoShouldBeOpened: function () {
            var $selected = this.$context.find('[data-selected]');
            if ($selected.length) {
                setTimeout(function () {
                    $selected.find('a:first').trigger('click');
                }, 1000);
            }
        }
    };

    if (ns && ns.app) {
        ns.app.registerModule('video', WwpVideo);
    } else {
        window.pew.addRegistryEntry({key: 'wdf-plugin-video', domSelector: '.module-video', classDef: WwpVideo});
    }

})(jQuery, window.wonderwp);

