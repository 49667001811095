export class GoogleProcessor {
  constructor(trackerEvent) {
    trackerEvent.subscribe('Tracking.pageEvent', (data) => this.registerPageEvent(data));
    trackerEvent.subscribe('Tracking.customEvent', (data) => this.registerCustomEvent(data));
  }

  registerPageEvent({page, type}) {
    if (type === 'view') {
      this.trackPageView(page)
    }
  }

  trackPageView(pageUrl) {

    //Universal Analytics
    if (window.ga) {
      window.ga('set', 'page', pageUrl);
      window.ga('send', 'pageview');
    }

    //GA4
    //No need to trigger this event manually, it should be automatically collected on page change or history change
    //https://support.google.com/analytics/answer/9234069
  }

  registerCustomEvent({action, label, category, cb}) {
    let Gtag = null;
    if (window.gtag) {
      Gtag = window.gtag;
    }
    if (Gtag) {
      // If Gtag fail, wait 3s to execute callback
      setTimeout(() => {
        if (cb) {
          cb('error');
        }
      }, 3000);

      // Documented here : https://developers.google.com/analytics/devguides/collection/gtagjs/events
      Gtag('event', action, {
        'event_callback': function () {cb && cb('success');},
        'event_category': category,
        'event_label': label
      });
    } else {
      cb && cb('notag');
    }
  }
}
