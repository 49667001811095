export class CachePluginComponent {
  constructor(context) {
    this.debug = false;
    this.storageKey = "wwp-cache";
    this.storageEnabled = true;
    this.EventManager = window.EventManager || $(document);
    this.onCacheNeedsRefresh(this.EventManager);
    //Necessary otherwise event emitted before listeners listen.
    setTimeout(() => {
      this.loadCacheData();
    }, 50);
  }

  loadCacheData() {
    if (!wonderwp.cache_enabled) {
      return false;
    }
    if (!wonderwp.cacheConfig || !wonderwp.cacheConfig.uri) {
      throw new Error('Missing wwp-cache api cache uri');
    }

    let res = this.storageEnabled ? sessionStorage.getItem(this.storageKey) : false;
    if (res) {
      this.log('Load Cache Data (storage)');
      this.onCacheLoadSuccess(JSON.parse(res), EventManager);
    } else {
      this.log('Load Cache Data (api)');
      $.get(wonderwp.cacheConfig.uri, {}, (res) => {
        if (res && res.code && res.code === 200) {
          sessionStorage.setItem(this.storageKey, JSON.stringify(res));
          this.onCacheLoadSuccess(res, EventManager);
        } else {
          this.onCacheLoadError(res, EventManager);
        }
      }, 'json');
    }
  }

  onCacheLoadSuccess(res, EventManager) {
    this.log('Emit wwp-cache-onCacheLoadSuccess');
    EventManager.trigger('wwp-cache-onCacheLoadSuccess', {
      res: res
    });
  }

  onCacheLoadError(res, EventManager) {
    EventManager.trigger('wwp-cache-onCacheLoadError', {
      res: res
    });
  }

  onCacheNeedsRefresh(EventManager) {
    EventManager.on('wwp-cache-onCacheNeedsRefresh', (event, eventData) => {
      this.log('wwp-cache-onCacheNeedsRefresh');
      sessionStorage.removeItem(this.storageKey);
    });
  }

  log(msg) {
    if (this.debug) {
      console.log('[Cache Component] ', ...arguments);
    }
  }
}

new CachePluginComponent();
