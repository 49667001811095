import {tns} from "tiny-slider/dist/tiny-slider";
import {PewComponent} from "../../../../assets/raw/js/components/pew-component";

export class SliderComponent extends PewComponent {

  constructor(element, options) {
    // see http://ganlanyuan.github.io/tiny-slider/#options
    let defaultOptions = {
      loop: false,
      items: 1,
      slideBy: 1,
      navPosition: "bottom",
      autoHeight: true,
      responsive: {
        768: {
          items: 2,
          slideBy: 2
        },
        960: {
          items: 3,
          slideBy: 3
        }
      }
    };
    super(element, Object.assign(defaultOptions,options));
  }

  init() {
    if (!this.options.container) {
      this.options.container = this.element[0];
    }
    this.slider = tns(this.options);
  }
}

window.pew.addRegistryEntry({key: 'wdf-slider', domSelector: '[class$="wdf-slider"]', classDef: SliderComponent});
window.pew.addRegistryEntry({key: 'wdf-timeline-slider', domSelector: '.wdf-timeline-slider', classDef: SliderComponent});
